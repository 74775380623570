<template>
  <div>
    <!--Banner Wrap Start-->
    <div class="iner_banner">
      <div class="container">
        <h5>Faculty</h5>
      </div>
    </div>
    <!--Banner Wrap End-->
    <div class="iq_content_wrap">
      <div class="container">
        <div class="mandatory-disclosures">
          <div style="margin-top: 20px; margin-bottom: 20px;">
            <p>&nbsp;</p>
            <p><b>YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DEGREE)</b></p>
            <p><b>Teaching Staff List 2022-23</b></p>
            <table>
              <tbody>
              <tr>
                <td>
                  <p><b>Sr. No</b></p>
                </td>
                <td>
                  <p><b>Name</b></p>
                </td>
                <td>
                  <p><b>Department</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>Dr. Rupali Tasgaonkar</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>Dr. Babita more&nbsp;</p>
                </td>
                <td>
                  <p>Pharmacognosy</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>Ms. Puja Vyawhare</p>
                </td>
                <td>
                  <p>Quality Assurance</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>Ms. Yogita Vispute</p>
                </td>
                <td>
                  <p>Pharm. Chemistry</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>5</p>
                </td>
                <td>
                  <p>Ms. Rajeshwari Khairnar</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>6</p>
                </td>
                <td>
                  <p>Ms. Gayatri Sapkale&nbsp;</p>
                </td>
                <td>
                  <p>Pharm. Chemistry</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>7</p>
                </td>
                <td>
                  <p>Mr. Ashish Jawale</p>
                </td>
                <td>
                  <p>Pharmacology</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>8</p>
                </td>
                <td>
                  <p>Ms. Ashwini Bharati</p>
                </td>
                <td>
                  <p>Quality Assurance</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>9</p>
                </td>
                <td>
                  <p>Ms. Tejashree Sonawane</p>
                </td>
                <td>
                  <p>Pharm. Chemistry</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>10</p>
                </td>
                <td>
                  <p>Ms. Ankita Gaikwad</p>
                </td>
                <td>
                  <p>Quality Assurance</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>11</p>
                </td>
                <td>
                  <p>Ms. Swati Pirdhankar</p>
                </td>
                <td>
                  <p>Pharmacology</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>12</p>
                </td>
                <td>
                  <p>Ms. Aasawaree Mane</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>13</p>
                </td>
                <td>
                  <p>Ms. Meenakshi Nikam</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>14</p>
                </td>
                <td>
                  <p>Mr. Umesh Shinde</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>15</p>
                </td>
                <td>
                  <p>Ms. Madhura More</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>16</p>
                </td>
                <td>
                  <p>Ms. Pooja Avhad</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>17</p>
                </td>
                <td>
                  <p>Ms. Prajakta Jadhav</p>
                </td>
                <td>
                  <p>Pharm. Chemistry</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>18</p>
                </td>
                <td>
                  <p>Aarati Labhane</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>19</p>
                </td>
                <td>
                  <p>Ms.Neha Singh</p>
                </td>
                <td>
                  <p>Quality Assurance</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>20</p>
                </td>
                <td>
                  <p>Ms. Karuna Magade</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>21</p>
                </td>
                <td>
                  <p>Ms. Ashwini Bachhav</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>22</p>
                </td>
                <td>
                  <p>Mr. Vivek Mahajan</p>
                </td>
                <td>
                  <p>Pharmaceutics</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>23</p>
                </td>
                <td>
                  <p>Ms. Shruti Deshmane</p>
                </td>
                <td>
                  <p>Quality Assurance</p>
                </td>
              </tr>
              </tbody>
            </table>
            <br><br>
            <p><b>Adjunct Staff List 2022-23</b></p>
            <table>
              <tbody>
              <tr>
                <td>
                  <p><b>Sr. No</b></p>
                </td>
                <td>
                  <p><b>Name</b></p>
                </td>
                <td>
                  <p><b>Department</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>Mr. Rohit M. Mali</p>
                </td>
                <td>
                  <p>Mathematics</p>
                </td>
              </tr>
              </tbody>
            </table>
            <br><br>
            <p><b>YADAAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DIPLOMA)</b></p>
            <p><b>Non-Teaching Staff list</b></p>
            <table>
              <tbody>
              <tr>
                <td>
                  <p><b>Sr. No</b></p>
                </td>
                <td>
                  <p><b>Name</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>1</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Bhanudas Khade</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>2</li>
                  </ul>
                </td>
                <td>
                  <p>Ms. Sangeeta Fadnis</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>3</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Satyawan Sawant</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>4</li>
                  </ul>
                </td>
                <td>
                  <p>Smt. Maya Mhaske</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>5</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Manoj Girnar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>6</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Jeevan Mote</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>7</li>
                  </ul>
                </td>
                <td>
                  <p>Ms. Varsha Juikar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>8</li>
                  </ul>
                </td>
                <td>
                  <p>Mrs. Harsha Chaudhari</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>9</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Rajesh Gaikwad</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>10</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Shirish Tasgaonkar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>11</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Bharat Bhagit</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>12</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Mahesh Bagade</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>13</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Sudam Kalan</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>14</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Sandeep Kolambe</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>15</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Pandit Bagade</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>16</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Bapu Bhagat</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>17</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Dilip Bhagit</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>18</li>
                  </ul>
                </td>
                <td>
                  <p>Ms. Chitra Khade</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>19</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Akshay Kamble&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul>
                    <li>20</li>
                  </ul>
                </td>
                <td>
                  <p>Mr. Vikrant Bhagat</p>
                </td>
              </tr>
              </tbody>
            </table>
            <br><br>
            <p><b>YADAVRAO TASGAONKAR INSTITUTE OF PHARMACY (DIPLOMA)</b></p>
            <p><b>Teaching staff Non-teaching staff</b></p>
            <table>
              <tbody>
              <tr>
                <td>
                  <p><b>Sr. No</b></p>
                </td>
                <td>
                  <p><b>Name</b></p>
                </td>
                <td>
                  <p><b>Sr. No</b></p>
                </td>
                <td>
                  <p><b>Name</b></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>Ms. Akshay Landge</p>
                </td>
                <td>
                  <p>1</p>
                </td>
                <td>
                  <p>Mr. Vasant Sulgaonkar</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>Ms. Jaya Yadav</p>
                </td>
                <td>
                  <p>2</p>
                </td>
                <td>
                  <p>Mr. Pralhad Talpe</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>Ms. Poonam Kasare</p>
                </td>
                <td>
                  <p>3</p>
                </td>
                <td>
                  <p>Ms. Jagruti Khade</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>Tejal Singh</p>
                </td>
                <td>
                  <p>4</p>
                </td>
                <td>
                  <p>Ms Sandhya Surywanshi</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>5</p>
                </td>
                <td>
                  <p>Pratiksha Kulthe</p>
                </td>
                <td>
                  <p>5</p>
                </td>
                <td>
                  <p>Mr. Mohan Kamble</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>6</p>
                </td>
                <td>
                  <p>Smita Bhairav</p>
                </td>
                <td>
                  <p>6</p>
                </td>
                <td>
                  <p>Mr. Manoj Rasal</p>
                </td>
              </tr>
              </tbody>
            </table>
            <br><br>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Faculty'
}
</script>

<style scoped>

</style>
